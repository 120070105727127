import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  pt: {
    translation: {
      "Seu e-mail": "Seu e-mail",
      "Sua senha": "Sua senha",
      Acessar: "Acessar",
      Carregando: "Carregando",
      "Criar conta": "Criar conta",
      "Insira um e-mail válido": "Insira um e-mail válido.",
      "O e-mail é obrigatório": "O e-mail é obrigatório.",
      "A senha é obrigatória": "A senha é obrigatória.",
      "Falha na autenticação": "Falha na autenticação, verifique seus dados.",
      "O nome é obrigatório": "O nome é obrigatório.",
      "A ocupação é obrigatória": "A ocupação é obrigatória.",
      "O celular é obrigatório": "O celular é obrigatório.",
      "O país é obrigatório": "O país é obrigatório.",
      "O estado é obrigatório": "O estado é obrigatório;",
      "A senha deve conter no mínimo 6 caracteres":
        "A senha deve conter no mínimo 6 caracteres.",
      "A confirmação da senha é obrigatória":
        "A confirmação da senha é obrigatória.",
      Estado: "Estado",
      "Nome completo": "Nome completo",
      "E-mail": "E-mail",
      Ocupação: "Ocupação",
      Celular: "Celular",
      País: "País",
      Senha: "Senha",
      "Confirmar senha": "Confirmar senha",
      "Já tenho login": "Já tenho login",
      "As senhas não coincidem": "As senhas não coincidem.",
      "Sua senha atual": "Sua senha atual",
      "Sua nova senha": "Sua nova senha",
      "Confirmar nova senha": "Confirmar nova senha",
      "Atualizar perfil": "Atualizar perfil",
      Sair: "Sair",
      "Perfil Atualizado com sucesso": "Perfil Atualizado com sucesso",
      "Erro ao atualizar perfil.": "Erro ao atualizar perfil.",
      PERMISSÕES: "PERMISSÕES",
      Permissões: "Permissões",
      Grupos: "Grupos",
      GRUPOS: "GRUPOS",
      "Meu perfil": "Meu perfil",
      "SOLICITAR ACESSO": "SOLICITAR ACESSO",
      "Solicitar acesso": "Solicitar acesso",
      "Pesquisar pelo e-mail": "Pesquisar pelo e-mail",
      Pesquisar: "Pesquisar",
      "Permissão salva": "Permissão salva.",
      "Erro ao atribuir permissão": "Erro ao atribuir permissão.",
      "Nenhum resultado": "Nenhum resultado",
      Salvar: "Salvar",
      "Você não tem permissão": "Você não tem permissão",
      "Grupo salvo": "Grupo salvo.",
      "Erro ao salvar grupo": "Erro ao salvar grupo.",
      "Pesquisar pelo nome": "Pesquisar pelo nome",
      "Novo grupo": "Novo grupo",
      Descrição: "Descrição",
      Resumo: "Resumo",
      Cursos: "Cursos",
      Cancelar: "Cancelar",
      "Atualizar grupo": "Atualizar grupo",
      "Adicionar membros": "Adicionar membros",
      "Não existe usuário nesse grupo": "Não existe usuário nesse grupo.",
      "Esse e-mail já existe": "Esse e-mail já existe.",
      "Esse usuário não existe":
        "Esse usuário não existe, gostaria de registrar um novo usuário?",
      Sim: "Sim",
      Não: "Não",
      "Membros adicionados com sucesso": "Membros adicionados com sucesso.",
      "Erro ao adicionar membros ao grupo":
        "Erro ao adicionar membros ao grupo.",
      "Adicionar cursos": "Adicionar cursos",
      "Não existe curso nesse grupo": "Não existe curso nesse grupo.",
      Membros: "Membros",
      "Adicionar Cursos": "Adicionar Cursos",
      "Cursos atuais": "Cursos atuais",
      "Cursos adicionados com sucesso": "Cursos adicionados com sucesso",
      "Erro ao adicionar cursos ao grupo": "Erro ao adicionar cursos ao grupo",
      "Usuário registrado": "Usuário registrado.",
      "Erro ao registrar usuário": "Erro ao registrar usuário.",
      "Escolha um grupo": "Escolha um grupo",
      "Esse usuário já existe": "Esse usuário já existe.",
      "Erro ao editar grupo": "Erro ao editar grupo.",
      "Grupo editado com sucesso": "Grupo editado com sucesso",
      "+Membros": "+ Membros",
      "+Cursos": "+ Cursos",
      Remover: "Remover",
      "Registrar usuário": "Registrar usuário",
      Inglês: "Inglês",
      Espanhol: "Espanhol",
      Português: "Português",
      "Sua conta de usuário foi criada":
        "Sua conta de usuário foi criada. Você ainda não tem permissões para intervir.",
      "Adicionar fabricoin": "Adicionar fabricoin",
      Quantidade: "Quantidade",
      "A quantidade é obrigatória": "A quantidade é obrigatória.",
      "Defina a quantidade de fabricoins grupo":
        "Defina a quantidade de fabricoins que você vai atribuir para cada membro do grupo.",
      "Defina a quantidade de fabricoins usuário":
        "Defina a quantidade de fabricoins que você vai atribuir para esse usuário.",
      "Fabricoin atribuído com sucesso": "Fabricoin atribuído com sucesso.",
      "Erro ao atribuir fabricoin": "Erro ao atribuir fabricoin.",
      "Não existe usuário no grupo.": "Não existe usuário no grupo.",
      RELATÓRIOS: "RELATÓRIOS",
      "Loading the report": "Carregando o relatório...",
      "Usuário não pertencente a nenhum grupo.":
        "Usuário não pertencente a nenhum grupo.",
      "Usuário não criou nenhum grupo e não pertence a nenhum.":
        "Usuário não criou nenhum grupo e não pertence a nenhum.",
      "Usuário não criou nenhum grupo.": "Usuário não criou nenhum grupo.",
      "Esse grupo já existe.": "Esse grupo já existe.",
    },
  },
  es: {
    translation: {
      "Seu e-mail": "Tu correo electrónico",
      "Sua senha": "Tu contraseña",
      Acessar: "Ingresar",
      Carregando: "Cargando",
      "Criar conta": "Crear cuenta",
      "Insira um e-mail válido": "Introduce un correo electrónico válido",
      "O e-mail é obrigatório": "El correo electrónico es obligatorio",
      "A senha é obrigatória": "La contraseña es obligatoria",
      "Falha na autenticação":
        "Falla en la autenticación, por favor revisa tus datos",
      "O nome é obrigatório": "El nombre es obligatorio",
      "A ocupação é obrigatória": "La ocupación es obligatoria",
      "O celular é obrigatório": "El celular es obligatorio",
      "O país é obrigatório": "El país es obligatorio",
      "O estado é obrigatório": "El estado es obligatorio",
      "A senha deve conter no mínimo 6 caracteres":
        "La contraseña debe tener al menos 6 caracteres",
      "A confirmação da senha é obrigatória":
        "La confirmación de la contraseña es obligatoria",
      Estado: "Estado",
      "Nome completo": "Nombre completo",
      "E-mail": "Correo electrónico",
      Ocupação: "Ocupación",
      Celular: "Celular",
      País: "País",
      Senha: "Contraseña",
      "Confirmar senha": "Confirmar contraseña",
      "Já tenho login": "Ya tengo una cuenta",
      "As senhas não coincidem": "Las contraseñas no coinciden",
      "Sua senha atual": "Tu contraseña actual",
      "Sua nova senha": "Tu nueva contraseña",
      "Confirmar nova senha": "Confirmar contraseña actual",
      "Atualizar perfil": "Actualizar perfil",
      Sair: "Salir",
      "Perfil Atualizado com sucesso": "Perfil actualizado con éxito",
      "Erro ao atualizar perfil.": "Error al actualizar el perfil",
      PERMISSÕES: "PERMISOS",
      Permissões: "Permisos",
      Grupos: "Grupos",
      GRUPOS: "GRUPOS",
      "Criar grupo": "Crear grupo",
      "CRIAR GRUPO": "CREAR GRUPO",
      "Meu perfil": "Mi perfil",
      "SOLICITAR ACESSO": "SOLICITAR ACCESO",
      "Solicitar acesso": "Solicitar acceso",
      "Pesquisar pelo e-mail": "Buscar por correo",
      Pesquisar: "Buscar",
      "Permissão salva": "Permiso guardado",
      "Erro ao atribuir permissão": "Error al atribuir permiso",
      "Nenhum resultado": "Ningún resultado",
      Salvar: "Guardar",
      "Você não tem permissão": "No tienes permiso",
      "Grupo salvo": "Grupo guardado",
      "Erro ao salvar grupo": "Error al guardar el grupo",
      "Pesquisar pelo nome": "Buscar por nombre",
      "Novo grupo": "Nuevo grupo",
      Descrição: "Descripción",
      Resumo: "Resumen",
      Cursos: "Cursos",
      Cancelar: "Cancelar",
      "Atualizar grupo": "Actualizar grupo",
      "Adicionar membros": "Añadir miembros",
      "Não existe usuário nesse grupo": "No hay usuarios en este grupo",
      "Esse e-mail já existe": "Este correo electrónico ya existe",
      "Esse usuário não existe":
        "¿Este usuario no existe, te gustaría registrar un nuevo usuario?",
      Sim: "Sí",
      Não: "No",
      "Membros adicionados com sucesso": "Miembros añadidos con éxito",
      "Erro ao adicionar membros ao grupo": "Error al añadir usuarios al grupo",

      "Adicionar cursos": "Añadir cursos",
      "Não existe curso nesse grupo": "No existe curso en este grupo",
      Membros: "Miembros",
      "Adicionar Cursos": "Añadir cursos",
      "Cursos atuais": "Cursos actuales",
      "Cursos adicionados com sucesso": "Cursos añadidos con éxito",
      "Erro ao adicionar cursos ao grupo": "Error al añadir cursos al grupo",
      "Usuário registrado": "Usuario registrado",
      "Erro ao registrar usuário": "Error al registrar usuario",
      "Escolha um grupo": "Selecciona un grupo",
      "Esse usuário já existe": "Este usuario ya existe",
      "Erro ao editar grupo": "Error al editar el grupo",
      "Grupo editado com sucesso": "Grupo editado con éxito",
      "+Membros": "+ Miembros",
      "+Cursos": "+ Cursos",
      Remover: "Quitar",
      "Registrar usuário": "Registrar usuario",
      Inglês: "Ingles",
      Espanhol: "Espanol",
      Português: "Portugués",
      "Sua conta de usuário foi criada":
        "Su cuenta de usuario ha sido creada. Aún no tienes permiso para intervenir.",
      "Adicionar fabricoin": "Añadir fabricoin",
      Quantidade: "Cantidad",
      "A quantidade é obrigatória": "Cantidad requerida.",
      "Defina a quantidade de fabricoins grupo":
        "Defina la cantidad de fabricoins que asignará a cada miembro del grupo.",
      "Defina a quantidade de fabricoins usuário":
        "Establezca la cantidad de fabricoins que asignará a ese usuario.",
      "Fabricoin atribuído com sucesso": "Fabricoin asignado correctamente.",
      "Erro ao atribuir fabricoin": "Error al asignar fabricoin",
      "Não existe usuário no grupo.": "No hay usuario en el grupo.",
      RELATÓRIOS: "INFORMES",
      "Loading the report": "Cargando el informe...",
      "Usuário não pertencente a nenhum grupo.":
        "Usuario no pertenece a ningún grupo.",
      "Usuário não criou nenhum grupo e não pertence a nenhum.":
        "Usuario no ha creado ningún grupo y no pertenece a ningún.",
      "Usuário não criou nenhum grupo.": "Usuario no ha creado ningún grupo.",
      "Esse grupo já existe.": "Este grupo ya existe.",
    },
  },
  en: {
    translation: {
      "Seu e-mail": "Your e-mail",
      "Sua senha": "Your password",
      Acessar: "Login",
      Carregando: "Loading",
      "Criar conta": "Create account",
      "Insira um e-mail válido": "Enter a valid e-mail address.",
      "O e-mail é obrigatório": "The e-mail address is a mandatory field",
      "A senha é obrigatória": "The password address is a mandatory field.",
      "Falha na autenticação":
        "Authentication failed, please check your information",
      "O nome é obrigatório": "The name address is a mandatory field.",
      "A ocupação é obrigatória":
        "The occupation address is a mandatory field.",
      "O celular é obrigatório": "The cell phone address is a mandatory field.",
      "O país é obrigatório": "The country address is a mandatory field.",
      "O estado é obrigatório": "The state address is a mandatory field.",
      "A senha deve conter no mínimo 6 caracteres":
        "Password must have at least 6 characters.",
      "A confirmação da senha é obrigatória":
        "The confirmation of password is a mandatory field",
      Estado: "State",
      "Nome completo": "Full name",
      "E-mail": "E-mail",
      Ocupação: "Occupation",
      Celular: "Cell phone",
      País: "Country",
      Senha: "Password",
      "Confirmar senha": "Confirm password",
      "Já tenho login": "I already have an account",
      "As senhas não coincidem": "Passwords don't match",
      "Sua senha atual": "Your current password",
      "Sua nova senha": "Your new password",
      "Confirmar nova senha": "Confirm new password",
      "Atualizar perfil": "Update profile",
      Sair: "Exit",
      "Perfil Atualizado com sucesso": "Profile successfully updated",
      "Erro ao atualizar perfil.": "Error on updating profile",
      PERMISSÕES: "PERMISSIONS",
      Permissões: "Permissions",
      Grupos: "Groups",
      GRUPOS: "GROUPS",
      "Criar grupo": "Create group",
      "CRIAR GRUPO": "CREATE GROUP",
      "Meu perfil": "Mi profile",
      "SOLICITAR ACESSO": "ASK FOR ACCESS",
      "Solicitar acesso": "Ask for access",
      "Pesquisar pelo e-mail": "Search by e-mail",
      Pesquisar: "Search",
      "Permissão salva": "Permission saved",
      "Erro ao atribuir permissão": "Error assigning permission",
      "Nenhum resultado": "None result",
      Salvar: "Save",
      "Você não tem permissão": "You don't have permission",
      "Grupo salvo": "Group saved",
      "Erro ao salvar grupo": "Error on saving group",
      "Pesquisar pelo nome": "Search by name",
      "Novo grupo": "New group",
      Descrição: "Description",
      Resumo: "Resume",
      Cursos: "Courses",
      Cancelar: "Cancel",
      "Atualizar grupo": "Update group",
      "Adicionar membros": "Add members",
      "Não existe usuário nesse grupo": "There is no users in this group",
      "Esse e-mail já existe": "This e-mail address already exists",
      "Esse usuário não existe":
        "This user does not exist, would you like to register a new user?",
      Sim: "Yes",
      Não: "No",
      "Membros adicionados com sucesso": "Members successfully added",
      "Erro ao adicionar membros ao grupo": "Error adding members to the group",
      "Erro ao adicionar membros ao grupo.":
        "Error adding members to the group",
      "Adicionar cursos": "Add courses",
      "Não existe curso nesse grupo": "There is no course in this group",
      Membros: "Members",
      "Adicionar Cursos": "Add Courses",
      "Cursos atuais": "Current courses",
      "Cursos adicionados com sucesso": "Courses successfully added",
      "Erro ao adicionar cursos ao grupo": "Error adding courses to the group",
      "Usuário registrado": "User registered",
      "Erro ao registrar usuário": "Error on registering user",
      "Escolha um grupo": "Select a group",
      "Esse usuário já existe": "This user already exists",
      "Erro ao editar grupo": "Error on editing group",
      "Grupo editado com sucesso": "Group succesfully edited",
      "+Membros": "+ Members",
      "+Cursos": "+ Courses",
      Remover: "Remove",
      "Registrar usuário": "Register user",
      Inglês: "English",
      Espanhol: "Spanish",
      Português: "Portuguese",
      "Sua conta de usuário foi criada":
        "Your user account has been created. You do not yet have permission to intervene.",
      "Adicionar fabricoin": "Add fabricoin",
      Quantidade: "Amount",
      "A quantidade é obrigatória": "Amount is required",
      "Defina a quantidade de fabricoins grupo":
        "Set the amount of fabricoins you will assign to each member of the group.",
      "Defina a quantidade de fabricoins usuário":
        "Set the amount of fabricoin you will assign to that user.",
      "Fabricoin atribuído com sucesso": "Fabricoin successfully assigned.",
      "Erro ao atribuir fabricoin": "Error assigning fabricoin.",
      "Não existe usuário no grupo.": "There is no user in the group.",
      RELATÓRIOS: "REPORTS",
      "Loading the report": "Loading the report...",
      "Usuário não pertencente a nenhum grupo.":
        "User does not belong to any groups.",
      "Usuário não criou nenhum grupo e não pertence a nenhum.":
        "User has not created any groups and does not belong to any",
      "Usuário não criou nenhum grupo.": "User has not created any groups.",
      "Esse grupo já existe.": "This group already exists.",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pt",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
