import axios from "axios";

const api = axios.create({
  // baseURL: "https://api-portal.fabricasim.com",
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export default api;
