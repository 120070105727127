import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { MdAccountCircle, MdMenu } from "react-icons/md";

import { Link } from "react-router-dom";
import SelectLang from "../SelectLang";

import logo from "../../assets/logo-delorenzo-2.png";

import { Container, Content, Profile, MenuMobile } from "./styles";

export default function Header() {
  const profile = useSelector((state) => state.user.profile);
  const { t } = useTranslation();
  const [visibleMenuMobile, setVisibleMenuMobile] = useState(false);

  function handleVisibleMenuMobile() {
    setVisibleMenuMobile(!visibleMenuMobile);
  }

  const authorizateCreateGroups = profile.roles.some((e) =>
    ["SUPER", "ADMIN+", "ADMIN"].includes(e)
  );
  const authorizatePermissions = profile.roles.some((e) =>
    ["SUPER", "ADMIN+", "ADMIN"].includes(e)
  );

  const authorizatePowerBi = profile.roles.some((e) =>
    ["SUPER", "ADMIN+", "ADMIN", "TEACHER", "STUDENT"].includes(e)
  );

  return (
    <Container>
      <Content>
        <nav>
          <img src={logo} alt="Delorenzo" />
          {authorizatePermissions && (
            <Link to="/permissions">{t("PERMISSÕES")}</Link>
          )}
          {authorizatePowerBi && <Link to="/reports">{t("RELATÓRIOS")}</Link>}
          {authorizateCreateGroups && <Link to="/groups">{t("GRUPOS")}</Link>}
        </nav>
        <aside>
          <button type="button" onClick={handleVisibleMenuMobile}>
            <MdMenu id="menu" size={30} />
          </button>
          <div id="selectlangweb">
            <SelectLang />
          </div>
          <Profile>
            <div>
              <strong>{profile.name}</strong>
              <Link to="/profile">{t("Meu perfil")}</Link>
            </div>
            <MdAccountCircle id="account-ico" size={30} />
          </Profile>
        </aside>
      </Content>
      {visibleMenuMobile && (
        <MenuMobile>
          <div id="select">
            <SelectLang />
          </div>
          <div id="links">
            {authorizatePermissions && (
              <Link to="/permissions">{t("Permissões")}</Link>
            )}
            {authorizateCreateGroups && <Link to="/groups">{t("Grupos")}</Link>}

            <Link to="/profile">{t("Meu perfil")}</Link>
          </div>
        </MenuMobile>
      )}
    </Container>
  );
}
